<template>
  <div class="sMianTop">
    <div class="sMianTopText">
      <div class="sMianTopTextBox">
        <van-icon
          :size="60"
          :color="salesStatus[getStatus].color"
          :name="salesStatus[getStatus].icon"
        />
        <div class="sMianTopTextBoxText">
          提交{{ salesStatus[getStatus].text }}
        </div>
      </div>
      <div class="sMianTopTextBox">
        <van-button type="info" block @click="submitResponse(getStatus)">
          {{
            salesStatus[getStatus].text === "成功" ? "查看申请记录" : "重新提交"
          }}</van-button
        >
        <van-button type="default" block color="#dbdbdb" @click="goHome"
          >回到首页</van-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { Notify } from 'vant';
export default {
  name: "successStatus",
  data() {
    return {
      salesStatus: {
        0: { text: "失败", color: "#FF0000", icon: "clear" },
        1: { text: "成功", color: "#1E90FF", icon: "checked" },
      },
      getStatus: "0", // 后端返回的成功或者失败状态
      resultObject: {},
    };
  },
  created() {
    // 处理截图浏览器返回的值
    this.resultObject = this.getFinalResult() || "";
    if (window.location.href && this.resultObject.status == "OK") {
      this.getStatus = "1";
    }
  },
  mounted() {},
  methods: {
    // 调用解析的方法最红结果
    getFinalResult() {
      let params = decodeURIComponent(window.location.href)
        .split("?")[1]
        .split("&rps=");
      let [queryParams, rpsValue] = params;
      let qbObject1 = this.extractParams(decodeURIComponent(queryParams));
      let qbObject = this.convertStringToObj(rpsValue);
      let zon = Object.assign(qbObject, { ...qbObject1 });
      return zon;
    },
    // 解析url携带的参数地址
    extractParamsFromURL(url) {
      const queryString = url.split("?")[1]; // 获取问号后面的部分
      return queryString ? decodeURIComponent(queryString) : ""; // 如果有参数则解码并返回，否则返回空字符串
    },
    // 转换成对象形式
    extractParams(queryString) {
      let params = {};
      queryString.split("&").forEach((param) => {
        const [key, value] = param.split("=");
        params[key] = decodeURIComponent(value.replace(/\+/g, " "));
      });
      return params;
    },
    // 转换json对象
    convertStringToObj(str) {
      // 解析 JSON 字符串
      let jsonObj = JSON.parse(str);
      if (jsonObj.signedValue) {
        jsonObj.signedValue = JSON.parse(jsonObj.signedValue);
      }
      return jsonObj;
    },
    /**
     * 代码描述:
     * 作者:liushiban
     * 创建时间:
     */
    submitResponse(status) {
      if (status == "1") {
        this.$router.push({
          name: "index",
        });
      } else {
        let { signedValue } = this.resultObject;
        let URL = `http://ankbkr.natappfree.cc/api/firmMemberOpen/getRegInviteLink?bizUserId=${signedValue.bizUserId}&companyName=${signedValue.companyName}` || '';
        axios
          .get(URL)
          .then((responseData) => {
            if (responseData.status == "200") {
              // 请求成功时的处理
              // 在这里可以对 responseData 进行进一步处理
              let { regInviteLink } = JSON.parse(responseData.data.data);
              // 跳转第三方链接
              window.location.href = regInviteLink || "";
            } else {
              Notify({ type: 'warning', message: '跳转失败,请回到首页从新开通' });
              return responseData;
            }
          })
          .catch((error) => {
            // 请求失败时的处理
            Notify({ type: 'warning', message: '跳转失败,请回到首页从新开通' });
            return error;
          });
      }
    },
    // 回到首页
    goHome() {
      this.$router.push({
        name: "index",
      });
    },
  },
};
</script>

<style scoped lang="scss">
.sMianTop {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  .sMianTopText {
    display: flex;
    flex-direction: column;
    margin-bottom: 100px;
    .sMianTopTextBox {
      margin: 30px 0;
      display: flex;
      flex-direction: column;
      .sMianTopTextBoxText {
        font-size: 20px;
        margin-top: 10px;
        font-weight: 400;
      }
    }
  }
}
.van-button {
  width: 200px;
  margin: 10px 0;
}
.van-button:last-child {
  color: #333333 !important;
}
</style>
